/* eslint-disable no-irregular-whitespace */
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import MetaImage from '../assets/images/customers-meta.png'
import Block from '../components/Block'
import CTABlock from '../components/blocks/CTABlock'
import { DarkLogoWallBlock } from '../components/blocks/DarkLogoWallBlock'
import HeroBlock from '../components/blocks/HeroBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import CustomerStoryTile from '../components/CustomerStoryTile'
import Layout from '../components/Layout'
import Box from '../components/primitives/Box'
import Testimonials from '../components/Testimonials'
import theme from '../theme'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/backgrounds/customers-dark.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    miro: file(relativePath: { eq: "images/story/miro/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, height: 343, layout: FULL_WIDTH)
      }
    }
    truelayer: file(
      relativePath: { eq: "images/story/truelayer/truelayer-hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 300, height: 343, layout: FULL_WIDTH)
      }
    }
    productsup: file(
      relativePath: { eq: "images/story/productsup/productsup-hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 300, height: 343, layout: FULL_WIDTH)
      }
    }
    gorgias: file(relativePath: { eq: "images/story/gorgias/hero-logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300, height: 343, layout: FULL_WIDTH)
      }
    }
    factorial: file(
      relativePath: { eq: "images/story/factorial/factorial-hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 300, height: 343, layout: FULL_WIDTH)
      }
    }
    choco: file(relativePath: { eq: "images/story/choco/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, height: 343, layout: FULL_WIDTH)
      }
    }
    factoryfix: file(
      relativePath: { eq: "images/story/factoryfix/factoryfix-hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 300, height: 343, layout: FULL_WIDTH)
      }
    }
    prima: file(relativePath: { eq: "images/story/prima/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, height: 343, layout: FULL_WIDTH)
      }
    }
    pleo: file(relativePath: { eq: "images/story/pleo/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, height: 343, layout: FULL_WIDTH)
      }
    }
    skutopia: file(relativePath: { eq: "images/story/skutopia/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, height: 343, layout: FULL_WIDTH)
      }
    }
    hostaway: file(
      relativePath: { eq: "images/story/hostaway/hostaway-hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 300, height: 343, layout: FULL_WIDTH)
      }
    }
  }
`

const CustomersPage = () => {
  const data = useStaticQuery(query)
  const miroPhoto = data.miro.childImageSharp.gatsbyImageData
  const truelayerPhoto = data.truelayer.childImageSharp.gatsbyImageData
  const productsupPhoto = data.productsup.childImageSharp.gatsbyImageData
  const gorgiasPhoto = data.gorgias.childImageSharp.gatsbyImageData
  const factorialPhoto = data.factorial.childImageSharp.gatsbyImageData
  const chocoPhoto = data.choco.childImageSharp.gatsbyImageData
  const factoryfixPhoto = data.factoryfix.childImageSharp.gatsbyImageData
  const primaPhoto = data.prima.childImageSharp.gatsbyImageData
  const pleoPhoto = data.pleo.childImageSharp.gatsbyImageData
  const skutopiaPhoto = data.skutopia.childImageSharp.gatsbyImageData
  const hostawayPhoto = data.hostaway.childImageSharp.gatsbyImageData

  return (
    <Layout
      title="Customers"
      description="Effective software organizations from startups to enterprises use Swarmia to improve business outcomes, developer productivity, and developer experience."
      metaImage={MetaImage}
      variant="dark"
      isNew
    >
      <HeroBlock
        title="Meet our customers"
        content="Effective software organizations from startups to enterprises use Swarmia to improve business outcomes, developer productivity, and developer experience."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <DarkLogoWallBlock />
      <Box
        // This is needed for the styles to work
        className="main-body-old-styles"
      >
        <Testimonials />
      </Box>
      <LeadBlock
        heading="Case studies"
        content="Learn how modern software engineering organizations use Swarmia to ship better software faster."
      />
      <Box
        // This is needed for the styles to work
        className="main-body-old-styles"
      >
        <Block type="padding-top-none padding-bottom-large">
          <RowContainer>
            <div className="testimonials">
              <CustomerStoryTile
                company="Miro"
                text="delivers relevant insights to every level of the engineering organization"
                image={miroPhoto}
              />
              <CustomerStoryTile
                company="TrueLayer"
                text="drives engineering effectiveness at every level of the organization"
                image={truelayerPhoto}
              />
            </div>
          </RowContainer>
          <RowContainer>
            <div className="testimonials">
              <CustomerStoryTile
                company="Pleo"
                text="empowers engineering teams to deliver customer value faster"
                image={pleoPhoto}
              />
              <CustomerStoryTile
                company="Factorial"
                text="drives continuous improvement during rapid growth"
                image={factorialPhoto}
              />
            </div>
          </RowContainer>
          <RowContainer>
            <div className="testimonials">
              <CustomerStoryTile
                company="Choco"
                text="improves transparency, autonomy, and alignment"
                image={chocoPhoto}
              />
              <CustomerStoryTile
                company="Hostaway"
                text="ships faster while increasing engineering headcount"
                image={hostawayPhoto}
              />
            </div>
          </RowContainer>
          <RowContainer>
            <div className="testimonials">
              <CustomerStoryTile
                company="Gorgias"
                text="uses Swarmia to continuously improve the flow of delivery"
                image={gorgiasPhoto}
              />
              <CustomerStoryTile
                company="SKUTOPIA"
                text="scales developer experience with engineering metrics and surveys"
                image={skutopiaPhoto}
              />
            </div>
          </RowContainer>
          <RowContainer>
            <div className="testimonials">
              <CustomerStoryTile
                company="Prima"
                text="transforms engineering productivity with healthy metrics"
                image={primaPhoto}
              />
              <CustomerStoryTile
                company="FactoryFix"
                text="boosts alignment and engineering productivity"
                image={factoryfixPhoto}
              />
            </div>
          </RowContainer>
          <RowContainer>
            <div className="testimonials">
              <CustomerStoryTile
                company="Productsup"
                text="supports team leads and speeds up software development"
                image={productsupPhoto}
              />
            </div>
          </RowContainer>
        </Block>
        <Block type="padding-top-none padding-bottom-large" />
      </Box>
      <CTABlock title="Your story starts here. Join the effective teams using Swarmia today." />
      <FeaturedBlogArticles
        articles={[
          '/blog/how-we-use-swarmia-at-swarmia/',
          '/blog/measuring-software-development-productivity/',
          '/blog/ship-software-10x-faster/',
        ]}
        heading="Learn more from our blog"
      />
    </Layout>
  )
}

const RowContainer = styled.div`
  margin-top: 56px;

  .testimonials {
    display: flex;
    flex-direction: row;
    justify-content: left;
    gap: 24px;

    @media screen and (max-width: ${theme.breakpoints.large}) {
      flex-direction: column;
      align-items: center;
    }
    @media screen and (max-width: ${theme.breakpoints.medium}) {
      gap: 24px;
    }
  }

  @media screen and (max-width: ${theme.breakpoints.large}) {
    margin-top: 32px;
  }
`

export default CustomersPage
